export const localStorageKey = {
  verify_alert_dialog: 'verify_alert_dialog',
  distribution: 'distribution',
  userLocation: 'userLocation',
  deviceId: 'deviceId',
  finishLoading: 'finishLoading',
  partnerDetail: 'partnerDetail',
  partnerBankAmount: 'partnerBankAmount',
  partnerBankAmountEditable: 'partnerBankAmountEditable',
  ifPartnerNavigatedToBankTransfer: 'ifPartnerNavigatedToBankTransfer',
  partnerLocQuery: 'partnerLocQuery',
  phone: 'phone',
  version: 'version',
  accessToken: 'accessToken',
  user_id: 'user_id',
  i18nextLng: 'i18nextLng',
  isLangSelected: 'isLangSelected',
  address_doc: 'address_doc',
  pan_doc: 'pan_doc',
  ref_id: 'ref_id',
  ckycAttempted: 'ckycAttempted',
  repayBankTxnId: 'repayBankTxnId',
  partnerRedirectDialog: 'partnerRedirectDialog',
  deeplinkParam: 'deeplinkParam',
  bankAccountId: 'bank_account_id',
  selfieCaptured: 'selfieCaptured',
  app_unavailable: 'app_unavailable',
  autoPayIntents: 'autoPayIntents',
  autopayRequired: 'autopayRequired'
};

export const autopaySubscriptionStatus = {
  PENDING: 'PENDING',
  SKIPPED: 'SKIPPED',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

// export const localStorageKey = {
//   isOnboardComplete: 'isOnboardComplete'
// };

export const completionStatus = {
  DONE: 'DONE',
  NOT_DONE: 'NOT_DONE'
};

export const distributionConst = {
  porter: 'porter',
  quess: 'quess',
  taskmo: 'taskmo',
  carzonrent: 'carzonrent',
  zypp: 'zypp',
  meraqui: 'meraqui',
  urban_clap: 'uc'
};
