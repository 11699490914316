import React, { useEffect, useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Container, Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { PATH_AUTH } from 'routes/paths';
import platform from 'platform';
import * as Yup from 'yup';
import _ from 'lodash';

import usePartner from 'hooks/usePartner';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';

import { localStorageKey } from 'utils/constants/common';
import { v1 as uuidv1 } from 'uuid';

import Page from 'components/Page';
import { LoginForm } from 'components/authentication/login';
import { appendSearchParams } from 'utils/common';
import useCommon from 'hooks/useComon';
import InitLanguages from './InitLanguages';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  maxHeight: '100vh',
  overflow: 'hidden',
  padding: '32px 24px 8px'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // padding: theme.spacing(0, 1, 1, 1),
  height: '100%'
}));

// ----------------------------------------------------------------------

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const LoginSchema = Yup.object().shape({
  phone: Yup.string()
    .length(10, 'otp_verify_toast_invalid_phone_number_message')
    .required('otp_verify_toast_invalid_phone_number_message'),
  is_allowed_to_contact_on_whatsapp: Yup.string()
});

export default function Login() {
  const [openLangDialog, setLangDialog] = useState(false);
  const { getInitPartnerPhoneNumber, initPartnerUserIfDetailsNotPresent } = usePartner();
  const { sendOtp } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { pushGaEvent } = useCommon();

  useEffect(() => {
    initPartnerUserIfDetailsNotPresent();
  }, []);

  const toggleLangDialog = () => setLangDialog((flag) => !flag);

  const formik = useFormik({
    initialValues: {
      phone: getInitPartnerPhoneNumber(),
      is_allowed_to_contact_on_whatsapp: true
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const isLangSelected = localStorage.getItem(localStorageKey.isLangSelected);
      if (isLangSelected === 'true') {
        await onLoginSubmit();
      } else {
        toggleLangDialog();
      }
    }
  });

  const onLoginSubmit = async () => {
    const { values, setErrors, setSubmitting, setFieldError } = formik;
    try {
      const usLocString = localStorage.getItem(localStorageKey.userLocation);
      const userLocation = !_.isEmpty(usLocString) ? JSON.parse(usLocString) : {};
      let device_id = localStorage.getItem(localStorageKey.deviceId);
      if (_.isEmpty(device_id)) {
        device_id = uuidv1();
        localStorage.setItem(localStorageKey.deviceId, device_id);
      }
      const params = {
        device_id,
        geoLocation: {
          latitude: userLocation?.latitude || 0,
          longitude: userLocation?.longitude || 0,
          fullAddress: userLocation?.display_name || 'unknown'
        },
        device_os: platform?.os?.family,
        device_model: platform?.version,
        phone: `91${values.phone}`,
        is_allowed_to_contact_on_whatsapp: values.is_allowed_to_contact_on_whatsapp
      };
      pushGaEvent('phone_verify_begin', { category: 'app_flow', action: 'auto' });
      await sendOtp(params);
      localStorage.setItem(localStorageKey.phone, values?.phone);
      window.dispatchEvent(new Event('storage'));
      // initPartnerUserIfDetailsNotPresent();
      navigate(appendSearchParams(PATH_AUTH.verify));

      if (isMountedRef.current) {
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      // resetForm();
      if (isMountedRef.current) {
        setSubmitting(false);
        setErrors({ afterSubmit: error.message || 'Error! Try again later' });
        if (error?.message) {
          setFieldError('phone', error?.message);
        }
      }
    }
  };

  const closeLanguageDialog = () => {
    setLangDialog(false);
  };
  return (
    <RootStyle title="Login | Karmalife">
      <Dialog onClose={closeLanguageDialog} open={openLangDialog} TransitionComponent={Transition}>
        <InitLanguages isLoginScreen closeLanguageDialog={closeLanguageDialog} onLoginSubmit={onLoginSubmit} />
      </Dialog>
      <Container maxWidth="md">
        <ContentStyle>
          <LoginForm toggleLangDialog={toggleLangDialog} formik={formik} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
