// import jwtDecode from 'jwt-decode';
// import { verify, sign } from 'jsonwebtoken';
// //
import _ from 'lodash';
import { localStorageKey } from 'utils/constants/common';
import { v1 as uuidv1 } from 'uuid';
import axios from './axios';
import { getUserLocation } from './locationHelper';

// const { versionCode } = require('../../package.json');

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(localStorageKey.accessToken, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
};

const createTransferParams = async (reqParams) => {
  let usLocString = localStorage.getItem('userLocation');

  if (_.isEmpty(usLocString)) {
    await getUserLocation();
    usLocString = localStorage.getItem('userLocation');
  }

  if (_.isEmpty(usLocString)) {
    await getUserLocation();
    usLocString = localStorage.getItem('userLocation');
  }
  const userLocation = !_.isEmpty(usLocString) ? JSON.parse(usLocString) : {};

  let device_id = localStorage.getItem(localStorageKey.deviceId);
  if (_.isEmpty(device_id)) {
    device_id = uuidv1();
    localStorage.setItem(localStorageKey.deviceId, device_id);
  }
  const updatedReq = {
    ...reqParams,
    device_id,
    location: {
      latitude: userLocation?.latitude || 0,
      longitude: userLocation?.longitude || 0,
      location: userLocation?.display_name || 'Location xyz ',
      state: userLocation?.address?.state || 'Karnataka',
      country: userLocation?.address?.country_code?.toUpperCase() || 'IN',
      postalCode: userLocation?.address?.postcode || '112233'
    }
  };
  return updatedReq;
};

const getUserId = () => localStorage.getItem(localStorageKey.user_id);

export { setSession, getUserId, createTransferParams };
