import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import queryString from 'query-string';
import { distributionConst, localStorageKey } from 'utils/constants/common';
import useAuth from 'hooks/useAuth';
import { clearPartnerCache, isValidPhoneNumber, clearPartnerAmountCache, appendSearchParams } from 'utils/common';
import useLocales from 'hooks/useLocales';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';

// Partner Configs

const initPartnerListForMobile = [
  distributionConst.taskmo,
  distributionConst.zypp,
  distributionConst.porter,
  distributionConst.urban_clap
];

const partnerListForBankAmount = [distributionConst.zypp];
/**
 * @deprecated("Call partner integration if you have a distbution query")
 */

const PartnerContext = createContext({
  getDistribution: () => {},
  initPartnerUser: () => {},
  getPartnerWelcomeImg: () => {},
  checkIfPartnerPhoneDisabled: () => {},
  getInitPartnerPhoneNumber: () => {},
  initPartnerUserIfDetailsNotPresent: () => {},
  checkInitPartnerPhoneNumber: () => {},
  checkIfPartnerDetailPresent: () => {},
  ifPartnerDirectBankTransfer: () => {},
  partnerNavigate: () => {}
});

function PartnerProvider({ children }) {
  const { initPartnerUser, isAuthenticated, isInitialized, logout } = useAuth();
  const { currentLang, onChangeLang, allLang } = useLocales();
  const navigate = useNavigate();

  const locationQuery = window.location.search;
  // decode params
  const queryValues = queryString.parse(locationQuery, true);
  const { product_type = '', partner_type = '', phone } = queryValues || {};
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    initPartnerUserHandler();
  }, [isInitialized]);

  function getDistribution() {
    const partner = localStorage.getItem(localStorageKey.distribution);

    let dist = '';
    const { d = '', distribution = '' } = queryValues || {};
    dist = d || distribution;
    if (!dist) {
      dist = partner;
    } else {
      localStorage.setItem(localStorageKey.distribution, dist);
    }
    return dist;
  }

  const initPartnerUserHandler = () => {
    const partnerDist = getDistribution();
    const partnerDetail = localStorage.getItem(localStorageKey.partnerDetail);
    const queryParamDetail = queryValues?.details;
    const queryPhoneNo = queryValues?.phone;
    const ifPartnerDetailDiff = queryParamDetail && queryParamDetail !== partnerDetail;

    if (locationQuery && queryParamDetail) {
      localStorage.setItem(localStorageKey.partnerLocQuery, locationQuery);
    }
    // set the phone number in local storage if obtained from url
    if (locationQuery && queryPhoneNo) {
      const phoneNum = localStorage.getItem(localStorageKey.phone, queryPhoneNo);
      if (phoneNum && phoneNum !== queryPhoneNo) {
        localStorage.removeItem(localStorageKey.accessToken);
        localStorage.removeItem(localStorageKey.phone);
        navigate(appendSearchParams(PATH_AUTH.login, { replace: true }));
        return window.location.reload();
      }
      localStorage.setItem(localStorageKey.phone, queryPhoneNo);
      window.dispatchEvent(new Event('storage'));
    }

    if (partnerDist) {
      // if (window?.gtag)
      //   window?.gtag('set', 'user_properties', {
      //     distribution: partnerDist
      //   });
      ReactGA.gtag('set', 'user_properties', { distribution: partnerDist });
      if (ifPartnerDetailDiff && isAuthenticated) {
        const userPhone = localStorage.getItem(localStorageKey.phone);
        const queryPhNo = queryPhoneNo || queryParamDetail;
        if (userPhone !== queryPhNo) logout();
        setTimeout(() => {
          initPartnerUserHandler();
        }, 100);
        return;
      }
    }
    // This is required step for the user creation of those partner where user details has
    // to be fetched from their server.
    if (partnerDist && queryValues?.details) {
      if (!isAuthenticated || ifPartnerDetailDiff) {
        const params = { details: queryValues?.details, distribution: partnerDist };
        if (product_type) {
          params.product_type = product_type || '';
        }
        if (partner_type) {
          params.partner_type = partner_type || '';
        }
        initPartnerUser(params);
      }
      if (ifPartnerDetailDiff) {
        localStorage.setItem(localStorageKey.partnerDetail, queryValues?.details);
      }
      // If Preferred language is passed
      if (!_.isEmpty(queryValues.lang)) {
        const prefLang = queryValues.lang;
        if (_.some(allLang, (lang) => lang?.value === prefLang)) {
          onChangeLang(prefLang);
        }
      }
      if (!_.isEmpty(queryValues.amount)) {
        if (!_.isNaN(Number(queryValues.amount)) && Number(queryValues.amount) > 0) {
          localStorage.setItem(localStorageKey.ifPartnerNavigatedToBankTransfer, false);
          localStorage.setItem(localStorageKey.partnerBankAmount, queryValues.amount);

          if (!_.isEmpty(String(queryValues.is_editable_amount))) {
            const isAmountEditable = String(queryValues.is_editable_amount) !== 'false';
            localStorage.setItem(localStorageKey.partnerBankAmountEditable, isAmountEditable);
          }
        } else {
          clearPartnerAmountCache();
        }
      }
    }

    if (!(queryValues?.d || queryValues?.distribution) && !isAuthenticated) {
      clearPartnerCache();
    }
  };

  const initPartnerUserIfDetailsNotPresent = (phoneNumber) => {
    const partner = localStorage.getItem(localStorageKey.distribution);
    const partnerDetail = localStorage.getItem(localStorageKey.partnerDetail);

    if (partner && _.isEmpty(partnerDetail)) {
      const params = { details: phoneNumber, distribution: partner };
      if (product_type) {
        params.product_type = product_type || '';
      }
      if (partner_type) {
        params.partner_type = partner_type || '';
      }
      if (phone) {
        params.phone_no = phone;
      }
      localStorage.setItem(localStorageKey.partnerDetail, phoneNumber);
      initPartnerUser(params);
    }
  };

  const getPartnerWelcomeImg = () => {
    const selLanguage = currentLang?.value || 'en';
    const partner = _.toLower(localStorage.getItem(localStorageKey.distribution));
    if (partner) {
      return `https://static.karmalife.ai/${partner}/welcome_${selLanguage}.webp`;
    }
    return `/static/auth/welcome_${selLanguage}.webp`;
  };

  const checkInitPartnerPhoneNumber = () => {
    const partner = localStorage.getItem(localStorageKey.distribution);

    return _.includes(initPartnerListForMobile, partner);
  };

  const checkIfPartnerPhoneDisabled = () => {
    const partner = localStorage.getItem(localStorageKey.distribution);
    const partnerDetail = localStorage.getItem(localStorageKey.partnerDetail);

    if (_.includes(initPartnerListForMobile, partner)) {
      return !_.isEmpty(partnerDetail);
    }

    return false;
  };

  const getInitPartnerPhoneNumber = () => {
    // prefer phone numer from query param 'phone'
    const queryPhone = (localStorage.getItem(localStorageKey.phone) || '')?.slice(-10);
    if (isValidPhoneNumber(queryPhone)) {
      return queryPhone || '';
    }
    // use 'details' param as phone only if it is valid and <= 12 characters
    const partnerDetail = localStorage.getItem(localStorageKey.partnerDetail) || '';
    const phone = partnerDetail?.slice(-10);
    if (isValidPhoneNumber(phone) && partnerDetail.length <= 12) {
      return phone || '';
    }
    return '';
  };

  const checkIfPartnerDetailPresent = () => {
    const partnerDetail = localStorage.getItem(localStorageKey.partnerDetail);
    return !_.isEmpty(partnerDetail);
  };

  const getPartnerBankAmount = () => {
    const partner = localStorage.getItem(localStorageKey.distribution);
    const amount = localStorage.getItem(localStorageKey.partnerBankAmount);
    return {
      shouldCheck: _.includes(partnerListForBankAmount, partner) && !_.isEmpty(amount),
      amount,
      isAmountPresent: !_.isEmpty(amount)
    };
  };

  const checkIfPartnerBankAmountEditable = () => {
    const partner = localStorage.getItem(localStorageKey.distribution);
    const ifPartnerAmountEditable = localStorage.getItem(localStorageKey.partnerBankAmountEditable) !== 'false';
    return { shouldCheck: _.includes(partnerListForBankAmount, partner), ifPartnerAmountEditable };
  };

  const ifPartnerDirectBankTransfer = () => {
    let action = false;
    const partnerBt = getPartnerBankAmount();
    const isAlreadyNavigated = localStorage.getItem(localStorageKey.ifPartnerNavigatedToBankTransfer) === 'true';
    // navigate only if deeplink not present
    const deeplinkEmpty = _.isEmpty(localStorage.getItem(localStorageKey.deeplinkParam));
    if (partnerBt.isAmountPresent && !isAlreadyNavigated && deeplinkEmpty) {
      localStorage.setItem(localStorageKey.ifPartnerNavigatedToBankTransfer, true);
      action = true;
    }
    return action;
  };

  // If this method is used to navigate, the partner query url is not loosed
  const partnerNavigate = (path, navProps) => {
    // We should not loose this data while navigating
    const partnerLocQuery = localStorage.getItem(localStorageKey.partnerLocQuery);
    navigate({ pathname: path, search: `?${partnerLocQuery}` }, { ...navProps });
  };

  return (
    <PartnerContext.Provider
      value={{
        getDistribution,
        initPartnerUser,
        getPartnerWelcomeImg,
        checkIfPartnerPhoneDisabled,
        getInitPartnerPhoneNumber,
        initPartnerUserIfDetailsNotPresent,
        checkInitPartnerPhoneNumber,
        checkIfPartnerDetailPresent,
        checkIfPartnerBankAmountEditable,
        getPartnerBankAmount,
        ifPartnerDirectBankTransfer,
        partnerNavigate
      }}
    >
      {' '}
      {children}
    </PartnerContext.Provider>
  );
}

PartnerProvider.propTypes = {
  children: PropTypes.node
};

export { PartnerProvider, PartnerContext };
