import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'hooks/useLocales';

// material
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AppBar, Toolbar, Typography, IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PATH_EWI, PATH_KYC, PATH_LOC_DASHBOARD, USAGE_REPAYMENT } from 'routes/paths';
import { useSelector } from 'react-redux';
// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: { backgroundColor: trigger ? 'white' : 'transparent', transition: 'all 0.2s ease' }
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: 'space-between',
  padding: '0px 24px'
}));

// ----------------------------------------------------------------------

CreditOfferNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function CreditOfferNavbar(props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selPlanDetails, creditPlanDetails } = useSelector(({ planReducer }) => planReducer);

  const handleBackClick = () => {
    switch (pathname) {
      case PATH_KYC.address_verify:
        navigate(PATH_LOC_DASHBOARD.creditOffer, { replace: true });
        break;
      case USAGE_REPAYMENT:
        navigate(-1);
        break;
      case PATH_LOC_DASHBOARD.language_preferred:
        navigate(-1, { replace: true });
        break;
      case PATH_EWI.ewi_language_preferred:
        navigate(-1, { replace: true });
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ color: 'black', fontWeight: 500, fontSize: 20 }} component="div">
                {selPlanDetails?.screen_title || creditPlanDetails?.screen_title}
              </Typography>
            </Stack>
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
