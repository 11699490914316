import React, { useEffect, useState } from 'react';
// routes
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import CommonErrorCard from 'components/CommonErrorCard';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/common';
import useLocales from 'hooks/useLocales';
import { localStorageKey } from 'utils/constants/common';
import { Box } from '@mui/material';

import { PartnerProvider } from 'contexts/PartnerContext';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

import useAuth from './hooks/useAuth';
import NotistackProvider from './components/NotistackProvider';
import ThemeLocalization from './components/ThemeLocalization';
// components
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
// import { subscribeNotificaton } from './serviceWorkerRegistration';
import { googleAnalyticsConfig, whatsApp } from './config';
import { dashboardHelpConst } from './utils/constants/kycConst';
import { getUserLocation } from './utils/locationHelper';
import { CommonProvider } from './contexts/CommonContext';

// ----------------------------------------------------------------------

// async function setNotification(user) {
//   await subscribeNotificaton({ vapidPublicKey, user });
// }

export default function App() {
  const { translate } = useLocales();

  ReactGA.initialize(googleAnalyticsConfig);

  const { isInitialized, logout } = useAuth();
  const dispatch = useDispatch();

  const { isRelogin = false } = useSelector(({ commonReducer }) => commonReducer);
  let deviceId = localStorage.getItem(localStorageKey.deviceId);
  const userLocation = localStorage.getItem(localStorageKey.userLocation);
  const [phone, setPhone] = useState('');

  React.useEffect(() => {
    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      setPhone(localStorage.getItem(localStorageKey.phone));
    });
  }, []);

  useEffect(() => {
    if (_.isEmpty(deviceId)) {
      deviceId = uuidv1();
      localStorage.setItem(localStorageKey.deviceId, deviceId);
      localStorage.setItem(localStorageKey.finishLoading, 0);
    }
  }, [deviceId]);

  useEffect(() => {
    if (navigator.geolocation && _.isEmpty(userLocation)) {
      getUserLocation();
    }
  }, [navigator, userLocation]);

  const onBtReLogin = () => {
    dispatch(commonActions.setReloginDialog(false));
    logout();
  };
  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${dashboardHelpConst}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };

  const phoneNo = localStorage.getItem(localStorageKey.phone);

  return (
    <>
      <ThemeConfig>
        <ThemeLocalization>
          {!_.isEmpty(phoneNo) && (
            <Box
              sx={{
                zIndex: 10000,
                position: 'absolute',
                top: 0,
                right: '10px',
                fontSize: '12px',
                color: 'secondary'
              }}
            >
              {phoneNo}
            </Box>
          )}
          <CommonErrorCard
            imageSrc="/Error.webp"
            dialogLabel={translate('dashboard_popup_error_title')}
            dialogContentText={translate('dashboard_popup_error_reason1')}
            buttonLabel={translate('common_popup_re_login_label')}
            buttonLabelClick={onBtReLogin}
            buttonTextLabel={translate('common_contact_support_label')}
            buttonTextLabelClick={onBtSupport}
            handleCloseDialog={(event, reason) => {
              if (!(reason === 'escapeKeyDown' || reason === 'backdropClick'))
                dispatch(commonActions.setReloginDialog(false));
            }}
            openDialog={isRelogin}
          />
          <ScrollToTop />
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />

            <CommonProvider>
              <PartnerProvider>
                {/* <Container maxWidth="xs" sx={{ p: 0 }}> */}
                {/* <Router /> */}
                {isInitialized ? <Router /> : <LoadingScreen />}
              </PartnerProvider>
            </CommonProvider>
            {/* </Container> */}
          </NotistackProvider>
        </ThemeLocalization>
      </ThemeConfig>
    </>
  );
}
